import React, { useEffect, useState, forwardRef, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getJobDoneTasks } from '../actions/taskActions'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import GetAppIcon from '@material-ui/icons/GetApp'
import usePrintPDF from '../components/PrintPdf'
import axios from 'axios'
import Pagination from '../components/Pagination'

const baseUrl = process.env.REACT_APP_API_URL

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const JobDoneTask = ({ history }) => {
    const navigate = useNavigate();
    const [searchLoading, setSearchLoading] = useState(false)
    const [data, setData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [selectedRemarks, setSelectedRemarks] = useState('')
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedTaskID, setSelectedTaskID] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedLoanType, setSelectedLoanType] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTatValues, setSelectedTatValues] = useState({})
    const [finalisationStatus, setFinalisationStatus] = useState('')
    const [showFinalisationRemarks, setShowFinalisationRemarks] = useState(false)
    const [finalisationRemarks, setFinalisationRemarks] = useState('')
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [limit] = useState(10) // Items per page

    const dispatch = useDispatch()
    const printPDF = usePrintPDF()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { getRoleData } = getRoleInfo

    useEffect(() => {
        if (getRoleData) {
            if (!getRoleData.completedTasks) {
                toast('Not Authorized', {
                    type: 'error',
                    hideProgressBar: true,
                    autoClose: 2000,
                })
                navigate('/')
            }
        }
    }, [userInfo, getRoleData, history])

    const fetchData = useCallback(() => {
        setSearchLoading(true)
        dispatch(getJobDoneTasks(currentPage, limit, searchQuery))
            .then(() => setSearchLoading(false))
            .catch(() => setSearchLoading(false))
    }, [dispatch, currentPage, limit, searchQuery])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchData()
        }, 300) // 300ms delay for search debounce

        return () => clearTimeout(delayDebounceFn)
    }, [fetchData])

    const getJobDoneTasksInfo = useSelector((state) => state.getJobDoneTasksInfo)
    const { loading: tasksLoading, error: tasksError, getJobDoneTasksData } = getJobDoneTasksInfo

    useEffect(() => {
        if (getJobDoneTasksData) {
            setLoading(false)
            setData(getJobDoneTasksData.data || [])
            setTotalPages(getJobDoneTasksData.totalPages || 1)
        } else if (tasksError) {
            setLoading(false)
            toast(tasksError, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getJobDoneTasksData, tasksError])

    const headCells = useMemo(() => [
        { field: 'taskID', title: 'Task ID' },
        { field: 'los', title: 'LOS' },
        { field: 'allocationRemarks', title: 'Allocation Remark' },
        { field: 'finalStatus', title: 'Final Status' },
        { field: 'customerName', title: 'Customer' },
        { field: 'finalTat', title: 'Final TAT' },
        { field: 'finalReport', title: 'Final Report' },
        { field: 'status', title: 'Status' },
        { field: 'name', title: 'Name' },
        { field: 'assignTo', title: 'Assign To' },
        { field: 'joinedOn', title: 'Allocation on field' },
        { field: 'address', title: 'Address' },
        { field: 'loanID', title: 'Loan ID' },
        { field: 'assignBy', title: 'Assign By' },
        { field: 'loanType', title: 'Loan Type' },
        { field: 'type', title: 'Type' },
        { field: 'finalDoneBy', title: 'Final Done By' },
        { field: 'finalJobOn', title: 'Final Done On' },
    ], [])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
        setCurrentPage(1) // Reset to first page on new search
    }

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        // Scroll to top of the page when changing pages
        window.scrollTo(0, 0);
    }, []);

    const handlePrintPDF = useCallback((row) => {
        if (row && row._id) {
            printPDF(row)
        } else {
            toast.error('Invalid task data')
        }
    }, [printPDF])

    // * Open View LOS Modal
    const openViewLosModal = (remarks, taskId, status, taskID, finalisationStatus, finalisationRemarks, name, type, loanType, joinedOn, address) => {
        setSelectedRemarks(remarks);
        setSelectedTaskId(taskId);
        setSelectedStatus(status);
        setSelectedTaskID(taskID);
        setSelectedName(name);
        setSelectedType(type);
        setSelectedLoanType(loanType);
        setSelectedTime(joinedOn);
        setSelectedAddress(address);
        setFinalisationStatus(finalisationStatus);
        setFinalisationRemarks(finalisationRemarks);
        setOpenModal(true);
    };

    const closeViewLosModal = () => {
        setOpenModal(false);
        setSelectedRemarks('');
        setSelectedTaskId(null);
        fetchData(); // Refresh the task list
    };

    const handleCopyRemarks = async () => {
        try {
            await navigator.clipboard.writeText(selectedRemarks);
            toast.success('Remarks copied successfully');
            setOpenModal(false);
        } catch (error) {
            console.error('Failed to copy remarks:', error);
            toast.error('Failed to copy remarks');
        }
    };
    const preventCopyPaste = (e) => {
        e.preventDefault()
        return false
    }

    const isWithinThreeMonths = useCallback((joinedDate) => {
        if (!joinedDate) return false;
        const joined = new Date(joinedDate);
        const now = new Date();
        const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3));
        return joined >= threeMonthsAgo;
    }, []);


    if (loading || tasksLoading) {
        return <Loading />
    }

    return (
        <>
            <div id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>Done Tasks</h1>
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>

                            <div className="row mb-4">
                                <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between w-50'>
                                        <input
                                            type="text"
                                            placeholder="Task ID, Loan ID, Allocation Remark"
                                            className="form-control"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    {searchLoading ? (
                                        <div className="flex justify-center items-center h-64">
                                            <Loading />
                                        </div>
                                    ) : (
                                        <div className="overflow-x-auto">
                                            <table className="w-full bg-white border border-gray-300">
                                                <thead>
                                                    <tr className="bg-gray-100">
                                                        {headCells.map((cell) => (
                                                            <th key={cell.field} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                {cell.title}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {data.map((row, index) => {
                                                        const rowColorClass =
                                                            row.finalTat === '4' ? 'bg-green-400' :
                                                                row.finalTat === '6' ? 'bg-green-300' :
                                                                    row.finalTat === '8' ? 'bg-purple-300' :
                                                                        row.finalTat === '9' ? 'bg-yellow-300' :
                                                                            row.finalTat === '10' || row.finalTat === '12' ? 'bg-red-400' :
                                                                                row.finalTat === '27' ? 'bg-red-500' :
                                                                                    '';

                                                        return (
                                                            <tr key={index} className={rowColorClass}>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <Link
                                                                        className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
                                                                        to={`/tasks/${row._id}`}>
                                                                        {row.taskID}
                                                                    </Link>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <button
                                                                        className={`${row.status === 'Sent To Bank'
                                                                            ? 'bg-green-500 hover:bg-green-700'
                                                                            : 'bg-yellow-500 hover:bg-yellow-700'
                                                                            } text-white text-sm font-semibold py-1 px-4 rounded focus:outline-none focus:shadow-outline`}
                                                                        onClick={() => openViewLosModal(row.remarks, row._id, row.status, row.taskID, row.finalisationStatus, row.finalisationRemarks, row.name, row.type, row.loanType, row.joinedOn, row.address)}
                                                                    >
                                                                        View LOS
                                                                    </button>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.allocationRemarks}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.finalisationStatus}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.customerName}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-center">{row.finalTat}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    {isWithinThreeMonths(row.joinedOn) && (
                                                                        <button
                                                                            className="text-blue-600 hover:text-blue-800"
                                                                            onClick={() => handlePrintPDF(row)}
                                                                            disabled={!row || !row._id}
                                                                        >
                                                                            <GetAppIcon />
                                                                        </button>
                                                                    )}
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.status}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap w-48">{row.name}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.assignTo}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.joinedOn}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.address}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.loanID}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.assignBy}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.loanType}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.type}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.finalJobBy}</td>
                                                                <td className="px-6 py-4 whitespace-nowrap">{row.finalJobOn}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>

                                            </table>
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    {openModal && (
                                        <>
                                            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                                                <div className='relative w-auto my-6 mx-auto max-w-3xl mt-6'>
                                                    <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[90vh] overflow-y-auto'>
                                                        <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t sticky top-0 bg-white z-10'>
                                                            <h3 className='text-lg font-semibold'>LOS - Task ID: {selectedTaskID} - Allocation On Field: {selectedTime}</h3>
                                                        </div>
                                                        <div className='relative flex-auto p-6 overflow-y-auto'>
                                                            <div className='flex gap-5 w-full'>
                                                                <div className='flex flex-col py-4'>
                                                                    <label className='text-sm mb-2' htmlFor='finalStatus'>
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="name"
                                                                        value={selectedName}
                                                                        readOnly
                                                                        className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                                    />
                                                                </div>
                                                                <div className='flex flex-col py-4'>
                                                                    <label className='text-sm mb-2' htmlFor='finalStatus'>
                                                                        Type
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="type"
                                                                        value={selectedType}
                                                                        readOnly
                                                                        className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                                    />
                                                                </div>
                                                                <div className='flex flex-col py-4'>
                                                                    <label className='text-sm mb-2' htmlFor='finalStatus'>
                                                                        Loan Type
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="type"
                                                                        value={selectedLoanType}
                                                                        readOnly
                                                                        className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='relative w-full my-6 mx-auto'>
                                                                <label className='text-sm mb-2' htmlFor='finalStatus'>
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className='text-sm w-full bg-gray-100 p-2 rounded '
                                                                    value={selectedAddress}
                                                                    readOnly
                                                                    rows={3}
                                                                />
                                                            </div>
                                                            <div className='bg-white shadow-md rounded px-8 py-4 w-full mb-4'>
                                                                <h5 className='font-semibold text-md'>Finalisation Details</h5>
                                                                <div className='flex flex-col py-4'>
                                                                    <label className='text-sm mb-2' htmlFor='finalStatus'>
                                                                        Final Status
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="finalisationStatus"
                                                                        value={finalisationStatus}
                                                                        readOnly
                                                                        className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                                    />
                                                                </div>
                                                                {(finalisationStatus === 'Negative' || finalisationStatus === 'Refer') && (
                                                                    <div className='flex flex-col'>
                                                                        <label className='text-sm mb-2' htmlFor='finalisationRemarks'>
                                                                            Finalisation Remarks
                                                                        </label>
                                                                        <textarea
                                                                            id="finalisationRemarks"
                                                                            value={finalisationRemarks}
                                                                            readOnly
                                                                            rows={1}
                                                                            className='bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='relative w-full max-w-3xl my-6 mx-auto px-4'>
                                                                <textarea
                                                                    className='text-sm w-full h-64 bg-gray-100 p-2 rounded resize-none'
                                                                    value={selectedRemarks}
                                                                    readOnly
                                                                    onCopy={preventCopyPaste}
                                                                    onCut={preventCopyPaste}
                                                                    onPaste={preventCopyPaste}
                                                                    onContextMenu={preventCopyPaste}
                                                                />
                                                                <p className='text-sm font-bold text-red-600 mt-2'>
                                                                    *Please Copy LOS from the Copy Remark Button
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                                                            <button
                                                                className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                                type='button'
                                                                onClick={closeViewLosModal}
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                                type='button'
                                                                onClick={handleCopyRemarks}
                                                            >
                                                                Copy Remarks
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default React.memo(JobDoneTask)
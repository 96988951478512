import axios from 'axios';
import {
  TASK_UPLOAD_REQUEST,
  TASK_UPLOAD_SUCCESS,
  TASK_UPLOAD_FAIL,
  COLLECTION_UPLOAD_REQUEST,
  COLLECTION_UPLOAD_SUCCESS,
  COLLECTION_UPLOAD_FAIL,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS_RESET,
  GET_BANKTASKS_REQUEST,
  GET_BANKTASKS_SUCCESS,
  GET_BANKTASKS_FAIL,
  GET_BANKTASKS_RESET,
  SEARCH_TASKS_REQUEST,
  SEARCH_TASKS_SUCCESS,
  SEARCH_TASKS_FAIL,
  GET_FINALISATIONTASKS_REQUEST,
  GET_FINALISATIONTASKS_SUCCESS,
  GET_FINALISATIONTASKS_FAIL,
  GET_FINALISATIONTASKS_RESET,
  GET_COMPLETEDTASKS_REQUEST,
  GET_COMPLETEDTASKS_SUCCESS,
  GET_COMPLETEDTASKS_FAIL,
  GET_COMPLETEDTASKS_RESET,
  GET_JOBDONETASKS_REQUEST,
  GET_JOBDONETASKS_SUCCESS,
  GET_JOBDONETASKS_FAIL,
  GET_JOBDONETASKS_RESET,
  GET_INPROGRESSTASKS_REQUEST,
  GET_INPROGRESSTASKS_SUCCESS,
  GET_INPROGRESSTASKS_FAIL,
  GET_INPROGRESSTASKS_RESET,
  GET_TASKID_FAIL,
  GET_TASKID_REQUEST,
  GET_TASKID_SUCCESS,
  GET_TASKID_RESET,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_ID_FAIL,
  GET_TASK_BY_ID_RESET,
  TASK_CHECKED_FAIL,
  TASK_CHECKED_REQUEST,
  TASK_CHECKED_RESET,
  TASK_CHECKED_SUCCESS,
  TASK_FINALISATION_FAIL,
  TASK_FINALISATION_REQUEST,
  TASK_FINALISATION_RESET,
  TASK_FINALISATION_SUCCESS,
  TASK_UPDATE_REMARKS_FAIL,
  TASK_UPDATE_REMARKS_REQUEST,
  TASK_UPDATE_REMARKS_RESET,
  TASK_UPDATE_REMARKS_SUCCESS,
  TASK_UPDATE_SUBMIT_FAIL,
  TASK_UPDATE_SUBMIT_REQUEST,
  TASK_UPDATE_SUBMIT_RESET,
  TASK_UPDATE_SUBMIT_SUCCESS,
  UPDATE_TASK_BY_ID_FAIL,
  UPDATE_TASK_BY_ID_REQUEST,
  UPDATE_TASK_BY_ID_RESET,
  UPDATE_TASK_BY_ID_SUCCESS,
  DIAL_FAIL,
  DIAL_REQUEST,
  DIAL_RESET,
  DIAL_SUCCESS,
  UNASSIGNED_TASKS_REQUEST,
  UNASSIGNED_TASKS_SUCCESS,
  UNASSIGNED_TASKS_FAIL,
  UNASSIGNED_TASKS_RESET,
  TRANSFERRED_TASKS_REQUEST,
  TRANSFERRED_TASKS_SUCCESS,
  TRANSFERRED_TASKS_FAIL,
  TRANSFERRED_TASKS_RESET,
  GET_FIELDEXECUTIVETASKS_REQUEST,
  GET_FIELDEXECUTIVETASKS_SUCCESS,
  GET_FIELDEXECUTIVETASKS_FAIL,
  GET_FIELDEXECUTIVETASKS_RESET,
  REASSIGN_MULTIPLE_TASKS_REQUEST,
  REASSIGN_MULTIPLE_TASKS_SUCCESS,
  REASSIGN_MULTIPLE_TASKS_FAIL,
  REASSIGN_MULTIPLE_TASKS_RESET,
  WAIVE_TASK_FAIL,
  WAIVE_TASK_REQUEST,
  WAIVE_TASK_SUCCESS,
} from '../constants/taskConstants.js';
import { logout } from './userActions.js';

const baseUrl = process.env.REACT_APP_API_URL;

export const uploadTask = (file, customer) => async (dispatch, getState) => {
  try {
    dispatch({ type: TASK_UPLOAD_REQUEST });

    const formData = new FormData();
    formData.append('excelFile', file);
    formData.append('customer', customer);

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
      responseType: 'blob', // Set the response type to blob
    };

    const response = await axios.post(`${baseUrl}/tasks/taskupload`, formData, config);

    // Check if the response is an Excel file
    if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'processed_tasks.xlsx';
      link.click();

      dispatch({
        type: TASK_UPLOAD_SUCCESS,
        payload: { message: 'Tasks uploaded successfully and file downloaded.' }
      });

      // Return true to indicate successful download
      return true;
    } else {
      // If the response is not an Excel file, it's probably an error message in JSON format
      const reader = new FileReader();
      reader.onload = () => {
        const errorData = JSON.parse(reader.result);
        dispatch({
          type: TASK_UPLOAD_FAIL,
          payload: errorData.error || 'An unknown error occurred'
        });
      };
      reader.readAsText(response.data);

      // Return false to indicate unsuccessful download
      return false;
    }
  } catch (error) {
    dispatch({
      type: TASK_UPLOAD_FAIL,
      payload: error.response && error.response.data.error
        ? error.response.data.error
        : error.message
    });

    // Return false to indicate unsuccessful download
    return false;
  }
};

export const getFieldExecutiveTasks = (executiveId = '', page = 1, limit = 50, search = '', status = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FIELDEXECUTIVETASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${baseUrl}/tasks/field-executive-tasks?executiveId=${executiveId}&page=${page}&limit=${limit}&search=${search}&status=${status}`,
      config
    );

    dispatch({
      type: GET_FIELDEXECUTIVETASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_FIELDEXECUTIVETASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetFieldExecutiveTasks = () => (dispatch) => {
  dispatch({ type: GET_FIELDEXECUTIVETASKS_RESET });
};

export const getUnassignedTasks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: UNASSIGNED_TASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/tasks/unassigned`, config);

    dispatch({ type: UNASSIGNED_TASKS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: UNASSIGNED_TASKS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getTransferredTasks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSFERRED_TASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/tasks/transferred`, config);

    dispatch({ type: TRANSFERRED_TASKS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: TRANSFERRED_TASKS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const reassignMultipleTasks = (tasks) => async (dispatch, getState) => {
  try {
    dispatch({ type: REASSIGN_MULTIPLE_TASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`${baseUrl}/tasks/reassign-multiple`, { tasks }, config);

    dispatch({ type: REASSIGN_MULTIPLE_TASKS_SUCCESS, payload: data });
    dispatch(getUnassignedTasks()); // Refresh the list of unassigned tasks
  } catch (error) {
    dispatch({
      type: REASSIGN_MULTIPLE_TASKS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const collectionTask = (file) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_UPLOAD_REQUEST });

    const formData = new FormData();
    formData.append('excelFile', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const { data } = await axios.post(`${baseUrl}/tasks/collection`, formData, config);

    dispatch({
      type: COLLECTION_UPLOAD_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_UPLOAD_FAIL,
      payload: error.response && error.response.data.error
        ? error.response.data.error
        : error.message
    });
  }
};

// export const getTasks = () => async (dispatch) => {
//     try {
//         dispatch({ type: GET_TASKS_REQUEST });

//         const { data } = await axios.get('http://localhost:5000/tasks');

//         dispatch({
//             type: GET_TASKS_SUCCESS,
//             payload: data
//         });
//     } catch (error) {
//         dispatch({
//             type: GET_TASKS_FAIL,
//             payload: error.response && error.response.data.error
//                 ? error.response.data.error
//                 : error.message
//         });
//     }
// };
export const getTasks = (page = 0, pageSize = 10) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TASKS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/tasks?page=${page}&pageSize=${pageSize}`, config);

    dispatch({
      type: GET_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(logout());
      return;
    }
    dispatch({
      type: GET_TASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchTasks = (query, page = 0, pageSize = 10) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_TASKS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/tasks/search?query=${query}&page=${page}&pageSize=${pageSize}`, config);

    dispatch({
      type: SEARCH_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(logout());
      return;
    }
    dispatch({
      type: SEARCH_TASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBankTasks = (page = 1, limit = 10, search = '') => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BANKTASKS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${baseUrl}/tasks/bank-task?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`, config);

    dispatch({
      type: GET_BANKTASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // dispatch(logout());
      return;
    }
    dispatch({
      type: GET_BANKTASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getFinalisationTasks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FINALISATIONTASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/tasks/finalisation-task`, config)

    dispatch({
      type: GET_FINALISATIONTASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: GET_FINALISATIONTASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCompletedTasks = (page = 1, limit = 10, search = '') => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_COMPLETEDTASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${baseUrl}/tasks/completed-task?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`,
      config
    )

    dispatch({
      type: GET_COMPLETEDTASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    // Uncomment the following block if you want to handle 401 errors by logging out
    // if (error.response && error.response.status === 401) {
    //   dispatch(logout())
    //   return
    // }
    
    dispatch({
      type: GET_COMPLETEDTASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getJobDoneTasks = (page = 1, limit = 10, search = '') => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_JOBDONETASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${baseUrl}/tasks/jobdone-task?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`,
      config
    )

    dispatch({
      type: GET_JOBDONETASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    // Uncomment the following block if you want to handle 401 errors by logging out
    // if (error.response && error.response.status === 401) {
    //   dispatch(logout())
    //   return
    // }
    
    dispatch({
      type: GET_JOBDONETASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getInProgressTasks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_INPROGRESSTASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/tasks/inprogress-task`, config)

    dispatch({
      type: GET_INPROGRESSTASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    //   if (error.response.status === 401) {
    //     dispatch(logout())
    //     return
    //   }
    dispatch({
      type: GET_INPROGRESSTASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// get task for user
export const getTasksById = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TASKID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/tasks/taskid/${userId}`, config);

    dispatch({
      type: GET_TASKID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_TASKID_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    })
  }
}

// get task by id (form fields details)
export const getTaskById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TASK_BY_ID_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${baseUrl}/tasks/${id}`, config); // Adjust the URL as per your backend API

    dispatch({
      type: GET_TASK_BY_ID_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GET_TASK_BY_ID_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    });
  }
};

export const updateTaskByID =
  (
    id,
    name,
    mobile,
    address,
    loanType,
    branch,
    financeAmount,
    type,
    loanID,
    receiptDate,
    allocationRemarks,
    assignTo // * Only if role is allocator
  ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: UPDATE_TASK_BY_ID_REQUEST,
        })

        const {
          userLogin: { userInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.put(
          `${baseUrl}/tasks/${id}`,
          {
            name,
            mobile,
            address,
            loanType,
            branch,
            financeAmount,
            type,
            loanID,
            receiptDate,
            allocationRemarks,
            assignTo,
          },
          config
        )

        dispatch({
          type: UPDATE_TASK_BY_ID_SUCCESS,
          payload: data,
        })
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout())
          return
        }
        dispatch({
          type: UPDATE_TASK_BY_ID_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }

export const taskChecked = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TASK_CHECKED_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${baseUrl}/tasks/${id}/checked`, {}, config)

    dispatch({
      type: TASK_CHECKED_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TASK_CHECKED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateSubmitTask = (id, details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TASK_UPDATE_SUBMIT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${baseUrl}/tasks/update-submit/${id}`,
      details,
      config
    )

    dispatch({
      type: TASK_UPDATE_SUBMIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TASK_UPDATE_SUBMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTaskRemarks =
  (id, remarks) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TASK_UPDATE_REMARKS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          //   Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${baseUrl}/tasks/update-remarks/${id}`,
        { remarks },
        config
      )

      dispatch({
        type: TASK_UPDATE_REMARKS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: TASK_UPDATE_REMARKS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  export const taskFinalisation =
  (id, finalisationData) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TASK_FINALISATION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${baseUrl}/tasks/finalize/${id}`,
        finalisationData,
        config
      )

      dispatch({
        type: TASK_FINALISATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: TASK_FINALISATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const dial = (id, number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DIAL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${baseUrl}/tasks/dial/${id}/web`,
      { number },
      config
    )

    dispatch({
      type: DIAL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: DIAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const waive = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WAIVE_TASK_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${baseUrl}/tasks/${id}/waive`, {}, config)

    dispatch({
      type: WAIVE_TASK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: WAIVE_TASK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
const TASK_UPLOAD_REQUEST = 'TASK_UPLOAD_REQUEST';
const TASK_UPLOAD_SUCCESS = 'TASK_UPLOAD_SUCCESS';
const TASK_UPLOAD_FAIL = 'TASK_UPLOAD_FAIL';

const COLLECTION_UPLOAD_REQUEST = 'TASK_UPLOAD_REQUEST';
const COLLECTION_UPLOAD_SUCCESS = 'TASK_UPLOAD_SUCCESS';
const COLLECTION_UPLOAD_FAIL = 'TASK_UPLOAD_FAIL';

export const UNASSIGNED_TASKS_REQUEST = 'UNASSIGNED_TASKS_REQUEST';
export const UNASSIGNED_TASKS_SUCCESS = 'UNASSIGNED_TASKS_SUCCESS';
export const UNASSIGNED_TASKS_FAIL = 'UNASSIGNED_TASKS_FAIL';
export const UNASSIGNED_TASKS_RESET = 'UNASSIGNED_TASKS_RESET';

export const TRANSFERRED_TASKS_REQUEST = 'TRANSFERRED_TASKS_REQUEST';
export const TRANSFERRED_TASKS_SUCCESS = 'TRANSFERRED_TASKS_SUCCESS';
export const TRANSFERRED_TASKS_FAIL = 'TRANSFERRED_TASKS_FAIL';
export const TRANSFERRED_TASKS_RESET = 'TRANSFERRED_TASKS_RESET';

export const REASSIGN_TASK_REQUEST = 'REASSIGN_TASK_REQUEST';
export const REASSIGN_TASK_SUCCESS = 'REASSIGN_TASK_SUCCESS';
export const REASSIGN_TASK_FAIL = 'REASSIGN_TASK_FAIL';
export const REASSIGN_TASK_RESET = 'REASSIGN_TASK_RESET';

export const REASSIGN_MULTIPLE_TASKS_REQUEST = 'REASSIGN_MULTIPLE_TASKS_REQUEST';
export const REASSIGN_MULTIPLE_TASKS_SUCCESS = 'REASSIGN_MULTIPLE_TASKS_SUCCESS';
export const REASSIGN_MULTIPLE_TASKS_FAIL = 'REASSIGN_MULTIPLE_TASKS_FAIL';
export const REASSIGN_MULTIPLE_TASKS_RESET = 'REASSIGN_MULTIPLE_TASKS_RESET';

const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
const GET_TASKS_FAIL = 'GET_TASKS_FAIL';
const GET_TASKS_RESET = 'GET_TASKS_RESET';

export const SEARCH_TASKS_REQUEST = 'SEARCH_TASKS_REQUEST';
export const SEARCH_TASKS_SUCCESS = 'SEARCH_TASKS_SUCCESS';
export const SEARCH_TASKS_FAIL = 'SEARCH_TASKS_FAIL';
export const SEARCH_TASKS_RESET = 'SEARCH_TASKS_RESET';

export const GET_BANKTASKS_REQUEST = 'GET_BANKTASKS_REQUEST';
export const GET_BANKTASKS_SUCCESS = 'GET_BANKTASKS_SUCCESS';
export const GET_BANKTASKS_FAIL = 'GET_BANKTASKS_FAIL';
export const GET_BANKTASKS_RESET = 'GET_BANKTASKS_RESET';

export const GET_FINALISATIONTASKS_REQUEST = 'GET_FINALISATIONTASKS_REQUEST';
export const GET_FINALISATIONTASKS_SUCCESS = 'GET_FINALISATIONTASKS_SUCCESS';
export const GET_FINALISATIONTASKS_FAIL = 'GET_FINALISATIONTASKS_FAIL';
export const GET_FINALISATIONTASKS_RESET = 'GET_FINALISATIONTASKS_RESET';

export const GET_COMPLETEDTASKS_REQUEST = 'GET_COMPLETEDTASKS_REQUEST';
export const GET_COMPLETEDTASKS_SUCCESS = 'GET_COMPLETEDTASKS_SUCCESS';
export const GET_COMPLETEDTASKS_FAIL = 'GET_COMPLETEDTASKS_FAIL';
export const GET_COMPLETEDTASKS_RESET = 'GET_COMPLETEDTASKS_RESET';

export const GET_JOBDONETASKS_REQUEST = 'GET_JOBDONETASKS_REQUEST';
export const GET_JOBDONETASKS_SUCCESS = 'GET_JOBDONETASKS_SUCCESS';
export const GET_JOBDONETASKS_FAIL = 'GET_JOBDONETASKS_FAIL';
export const GET_JOBDONETASKS_RESET = 'GET_JOBDONETASKS_RESET';

export const GET_FIELDEXECUTIVETASKS_REQUEST = 'GET_FIELDEXECUTIVETASKS_REQUEST';
export const GET_FIELDEXECUTIVETASKS_SUCCESS = 'GET_FIELDEXECUTIVETASKS_SUCCESS';
export const GET_FIELDEXECUTIVETASKS_FAIL = 'GET_FIELDEXECUTIVETASKS_FAIL';
export const GET_FIELDEXECUTIVETASKS_RESET = 'GET_FIELDEXECUTIVETASKS_RESET';

export const GET_INPROGRESSTASKS_REQUEST = 'GET_FINALISATIONTASKS_REQUEST';
export const GET_INPROGRESSTASKS_SUCCESS = 'GET_FINALISATIONTASKS_SUCCESS';
export const GET_INPROGRESSTASKS_FAIL = 'GET_FINALISATIONTASKS_FAIL';
export const GET_INPROGRESSTASKS_RESET = 'GET_FINALISATIONTASKS_RESET';

export const UPDATE_TASK_BY_ID_REQUEST = 'UPDATE_TASK_BY_ID_REQUEST'
export const UPDATE_TASK_BY_ID_SUCCESS = 'UPDATE_TASK_BY_ID_SUCCESS'
export const UPDATE_TASK_BY_ID_FAIL = 'UPDATE_TASK_BY_ID_FAIL'
export const UPDATE_TASK_BY_ID_RESET = 'UPDATE_TASK_BY_ID_RESET'

// Define action types for fetching tasks
const GET_TASKID_REQUEST = 'GET_TASKID_REQUEST';
const GET_TASKID_SUCCESS = 'GET_TASKID_SUCCESS';
const GET_TASKID_FAIL = 'GET_TASKID_FAIL';
const GET_TASKID_RESET = 'GET_TASKID_RESET';

export const DIAL_REQUEST = 'DIAL_REQUEST'
export const DIAL_SUCCESS = 'DIAL_SUCCESS'
export const DIAL_FAIL = 'DIAL_FAIL'
export const DIAL_RESET = 'DIAL_RESET'

export const TASK_CHECKED_REQUEST = 'TASK_CHECKED_REQUEST'
export const TASK_CHECKED_SUCCESS = 'TASK_CHECKED_SUCCESS'
export const TASK_CHECKED_FAIL = 'TASK_CHECKED_FAIL'
export const TASK_CHECKED_RESET = 'TASK_CHECKED_RESET'

export const TASK_UPDATE_SUBMIT_REQUEST = 'TASK_UPDATE_SUBMIT_REQUEST'
export const TASK_UPDATE_SUBMIT_SUCCESS = 'TASK_UPDATE_SUBMIT_SUCCESS'
export const TASK_UPDATE_SUBMIT_FAIL = 'TASK_UPDATE_SUBMIT_FAIL'
export const TASK_UPDATE_SUBMIT_RESET = 'TASK_UPDATE_SUBMIT_RESET'

export const TASK_UPDATE_REMARKS_REQUEST = 'TASK_UPDATE_REMARKS_REQUEST'
export const TASK_UPDATE_REMARKS_SUCCESS = 'TASK_UPDATE_REMARKS_SUCCESS'
export const TASK_UPDATE_REMARKS_FAIL = 'TASK_UPDATE_REMARKS_FAIL'
export const TASK_UPDATE_REMARKS_RESET = 'TASK_UPDATE_REMARKS_RESET'

export const TASK_FINALISATION_REQUEST = 'TASK_FINALISATION_REQUEST'
export const TASK_FINALISATION_SUCCESS = 'TASK_FINALISATION_SUCCESS'
export const TASK_FINALISATION_FAIL = 'TASK_FINALISATION_FAIL'
export const TASK_FINALISATION_RESET = 'TASK_FINALISATION_RESET'

export const GET_TASK_BY_ID_REQUEST = 'GET_TASK_BY_ID_REQUEST';
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS';
export const GET_TASK_BY_ID_FAIL = 'GET_TASK_BY_ID_FAIL'
export const GET_TASK_BY_ID_RESET = 'GET_TASK_BY_ID_RESET'

export const WAIVE_TASK_REQUEST = 'WAIVE_TASK_REQUEST'
export const WAIVE_TASK_SUCCESS = 'WAIVE_TASK_SUCCESS'
export const WAIVE_TASK_FAIL = 'WAIVE_TASK_FAIL'
export const WAIVE_TASK_RESET = 'WAIVE_TASK_RESET'


export {
    TASK_UPLOAD_REQUEST,
    TASK_UPLOAD_SUCCESS,
    TASK_UPLOAD_FAIL,
    COLLECTION_UPLOAD_REQUEST,
    COLLECTION_UPLOAD_SUCCESS,
    COLLECTION_UPLOAD_FAIL,
    GET_TASKS_REQUEST,
    GET_TASKS_SUCCESS,
    GET_TASKS_FAIL,
    GET_TASKS_RESET,
    GET_TASKID_REQUEST,
    GET_TASKID_SUCCESS,
    GET_TASKID_FAIL,
    GET_TASKID_RESET
};
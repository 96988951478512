import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { getFieldTaskHourCounts } from '../actions/countActions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const FieldCountHour = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [hourlyTotals, setHourlyTotals] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [intervalHours, setIntervalHours] = useState(3)

    const dispatch = useDispatch()

    // * Check for auth
    const userLogin = useSelector((state) => state.userLogin)
    const { loadingUserInfo, userInfo } = userLogin

    useEffect(() => {
        // * Check if user info exists
        if (!userInfo) {
            navigate('/')
        }
    }, [userInfo])

    // * Check for role
    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { loadingGetRole, getRoleData } = getRoleInfo

    useEffect(() => {
        if (getRoleData) {
            if (!getRoleData.dashboard) {
                toast('Not Authorized', {
                    type: 'error',
                    hideProgressBar: true,
                    autoClose: 2000,
                })
                navigate('/')
            }
        }
    }, [userInfo, getRoleData])

    const getFieldTaskCountHourInfo = useSelector((state) => state.getFieldTaskCountHourInfo)
    const { loadingFieldTaskCountHourInfo, errorFieldTaskCountHourInfo, getFieldTaskCountHourData } = getFieldTaskCountHourInfo

    useEffect(() => {
        setIsLoading(true)
        dispatch(getFieldTaskHourCounts(intervalHours))
    }, [dispatch, intervalHours])

    useEffect(() => {
        if (getFieldTaskCountHourData) {
            setData(getFieldTaskCountHourData.fieldExecutives || [])
            setHourlyTotals(getFieldTaskCountHourData.hourlyTotals || [])
            setIsLoading(false)
        } else if (errorFieldTaskCountHourInfo) {
            toast.error(errorFieldTaskCountHourInfo, {
                hideProgressBar: true,
                autoClose: 2000,
            })
            setIsLoading(false)
        }
    }, [getFieldTaskCountHourData, errorFieldTaskCountHourInfo])

    const generateTimeIntervals = (intervalHours) => {
        const intervals = [];
        for (let i = 0; i < 24; i += intervalHours) {
            const start = i.toString().padStart(2, '0');
            const end = Math.min(i + intervalHours, 24).toString().padStart(2, '0');
            intervals.push({
                start: i,
                end: Math.min(i + intervalHours, 24),
                label: `${start}:00 - ${end}:00`
            });
        }
        return intervals;
    }

    const intervals = useMemo(() => generateTimeIntervals(intervalHours), [intervalHours]);

    const headCells = useMemo(() => [
        { title: 'FE Name', field: 'name' },
        ...intervals.map((interval) => ({
            title: interval.label,
            field: `interval_${interval.start}_${interval.end}`,
        })),
        { title: 'Total', field: 'total' }
    ], [intervals])

    const tableData = useMemo(() => {
        const rows = data.map(executive => {
            const rowData = { name: executive.name, total: 0 };
            intervals.forEach((interval) => {
                let count = 0;
                for (let hour = interval.start; hour < interval.end; hour++) {
                    count += executive.hourCounts[hour];
                }
                rowData[`interval_${interval.start}_${interval.end}`] = count;
                rowData.total += count;
            });
            return rowData;
        });

        // Add hourly totals row
        const totalsRow = { name: 'Total' };
        intervals.forEach((interval) => {
            let total = 0;
            for (let hour = interval.start; hour < interval.end; hour++) {
                total += hourlyTotals[hour];
            }
            totalsRow[`interval_${interval.start}_${interval.end}`] = total;
        });
        totalsRow.total = hourlyTotals.reduce((sum, count) => sum + count, 0);
        
        return [...rows, totalsRow];
    }, [data, intervals, hourlyTotals]);

    const options = useMemo(() => ({
        sorting: true,
        filtering: false,
        exportButton: true,
        exportAllData: true,
        search: true,
        rowStyle: (rowData) => ({
            height: '5px',
            fontSize: 13,
            fontWeight: rowData.name === 'Total' ? 'bold' : 'normal',
            backgroundColor: rowData.name === 'Total' ? '#f0f0f0' : 'white'
        }),
        paging: true,
        pageSize: pageSize,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [10, 20, 50],
        headerStyle: { position: 'sticky', top: '0' },
        showTitle: false,
        footer: true,
        footerStyle: { fontWeight: 'bold' },
    }), [pageSize])

    const handleIntervalChange = (event) => {
        setIntervalHours(Number(event.target.value));
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className='w-full h-full'>
            <h1 className='text-2xl font-semibold'>Field Executive Task Counts</h1>
            <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-5 items-center'>
                        <span>Interval Length:</span>
                        <Select
                            value={intervalHours}
                            onChange={handleIntervalChange}
                            className='w-32'
                        >
                            <MenuItem value={1}>1 hour</MenuItem>
                            <MenuItem value={2}>2 hours</MenuItem>
                            <MenuItem value={3}>3 hours</MenuItem>
                            <MenuItem value={4}>4 hours</MenuItem>
                            <MenuItem value={6}>6 hours</MenuItem>
                            <MenuItem value={8}>8 hours</MenuItem>
                            <MenuItem value={12}>12 hours</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <MaterialTable
                icons={tableIcons}
                title={''}
                columns={headCells}
                data={tableData}
                options={options}
                onChangePage={(newPage) => setPage(newPage)}
                onChangeRowsPerPage={(newPageSize) => setPageSize(newPageSize)}
            />
        </div>
    )
}

export default React.memo(FieldCountHour)
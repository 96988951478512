import React, { useEffect, useState, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import { uploadTask, getTasks, getFinalisationTasks } from '../actions/taskActions';
import { GET_FINALISATIONTASKS_RESET } from '../constants/taskConstants'
import Input from '../components/Input'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'

const baseUrl = process.env.REACT_APP_API_URL;

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const FinalisationTask = ({ history }) => {
    const [data, setData] = useState([])
    const [file, setFile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [customer, setCustomer] = useState('')

    const dispatch = useDispatch();
    const taskUpload = useSelector(state => state.taskUpload);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            setError('Please select a file');
            return;
        }

        if (!customer) {
            setError('Please select a customer');
            return;
        }

        dispatch(uploadTask(file, customer))
            .then(() => {
                toast.success('File uploaded successfully!');
                window.location.reload();
            })
            .catch((error) => {
                console.error('Upload failed:', error);
                toast.error('File upload failed. Please try again.');
            });
    };

    const loadingg = taskUpload && taskUpload.loading;

    const userLogin = useSelector((state) => state.userLogin)
    const { loadingUserInfo, userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            // history.push('/')
        }
    }, [userInfo])

    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { loadingGetRole, getRoleData } = getRoleInfo

    useEffect(() => {
        if (getRoleData) {
            if (!getRoleData.usersView) {
                // history.push('/')
            }
        }
    }, [userInfo, getRoleData, history])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(getFinalisationTasks(currentPage, searchQuery));
        }, 300) // 300ms delay

        return () => clearTimeout(delayDebounceFn)
    }, [dispatch, currentPage, searchQuery]);

    const getFinalisationTasksInfo = useSelector((state) => state.getFinalisationTasksInfo)
    const { loadingGetFinalisationTasksInfo, errorGetFinalisationTasksInfo, getFinalisationTasksData } = getFinalisationTasksInfo;

    useEffect(() => {
        dispatch({ type: GET_FINALISATIONTASKS_RESET })
        if (getFinalisationTasksData) {
            setLoading(false)
            setData(Array.isArray(getFinalisationTasksData.data) ? getFinalisationTasksData.data : []);
            setTotalPages(getFinalisationTasksData.totalPages || 1);
        } else if (errorGetFinalisationTasksInfo) {
            setLoading(false)
            toast(errorGetFinalisationTasksInfo, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getFinalisationTasksData, errorGetFinalisationTasksInfo])

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page + 1);
    };

    const headCells = [
        {
            field: 'taskID',
            title: 'Task ID',
            cellStyle: {
                minWidth: '150px',
            },
            headerStyle: {
                minWidth: '150px',
            },
            render: (rowData) => (
                <Link
                    className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
                    to={`/tasks/${rowData._id}`}>
                    {rowData.taskID}
                </Link>
            )
        },
        {
            field: 'assignTo',
            title: 'Assign To',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'name',
            title: 'Name',
            cellStyle: {
                minWidth: '200px',
            },
            headerStyle: {
                minWidth: '100px',
            },
        },
        {
            field: 'joinedOn',
            title: 'Allocation on field',
            cellStyle: {
                minWidth: '200px',
            },
        },
        {
            field: 'allocationRemarks',
            title: 'Allocation Remark',
            cellStyle: {
                minWidth: '200px',
            },
        },
        {
            field: 'status',
            title: 'Status',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'customerName',
            title: 'Customer',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'address',
            title: 'Address',
            cellStyle: {
                minWidth: '300px',
            },
        },
        {
            field: 'loanID',
            title: 'Loan ID',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'assignBy',
            title: 'Assign By',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'loanType',
            title: 'Loan Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'type',
            title: 'Type',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitStartedOn',
            title: 'Visit Start',
            cellStyle: {
                minWidth: '150px',
            },
        },
        {
            field: 'visitFinishedOn',
            title: 'Visit Finish',
            cellStyle: {
                minWidth: '150px',
            },
        },
    ]

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h1 className='text-2xl font-semibold'>Finalisation Tasks</h1>
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"></li>
                            </ol>

                            <div className="row mb-4">
                                {/* <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                                    <div className='flex justify-between'>
                                        <input
                                            type="text"
                                            placeholder="Global Search..."
                                            className={`block appearance-none w-50 bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm mr-4`}
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />
                                    </div>
                                </div> */}

                                <MaterialTable
                                    icons={tableIcons}
                                    title={''}
                                    columns={headCells}
                                    data={data}
                                    options={{
                                        exportButton: true,
                                        search: true,
                                        exportAllData: true,
                                        rowStyle: {
                                            height: '5px',
                                            fontSize: 13,
                                        },
                                        paging: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 20, 50],
                                        headerStyle: {
                                            position: 'sticky',
                                            top: '0',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default FinalisationTask
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { getRole, logout } from '../actions/userActions'
import Home from '@material-ui/icons/Home'
import Assignment from '@material-ui/icons/Assignment'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import GroupIcon from '@material-ui/icons/Group'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import TransformOutlined from '@material-ui/icons/TransformOutlined'
import Assessment from '@material-ui/icons/Assessment'
import Settings from '@material-ui/icons/Settings'
import PersonPinCircle from '@material-ui/icons/PersonPinCircle'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import logo from '../logo.png'
import pjson from '../../package.json'

const Sidebar = ({ route, history }) => {
  // * Initialization
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();

  // * On logout click
  const logoutClick = () => {
    dispatch(logout())
    navigate('/')
  }

  // * States
  const [name, setName] = useState('')
  const [role, setRole] = useState({})
  const [isReportOpen, setIsReportOpen] = useState(false)

  // * Get logged in user's info
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
      dispatch(getRole())
    }
  }, [userInfo])

  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, errorGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      setRole(getRoleData)
    } else if (errorGetRole) {
      logoutClick()
    }
  }, [getRoleData, errorGetRole])

  const toggleReportDropdown = () => {
    setIsReportOpen(!isReportOpen)
  }

  return (
    <div className='w-1/5 min-h-screen absolute sm:relative bg-[#013975] shadow flex-col justify-between hidden sm:flex overflow-x-auto'>
      <div className='px-8'>
        <div className='h-16 w-full flex flex-row items-center gap-2 mt-5'>
          {/* <img src={logo} alt='GoLog' className='w-20' /> */}
          <h3 className='text-2xl font-semibold text-white'>GoLog Pro</h3>
          {/* <p className='text-sm text-white mt-1'>()</p> */}
          <button className='btn btn-success'>
            V{pjson.version}
          </button>
        </div>
        {!loadingGetRole && (
          <ul className='mt-12'>
            {role.settings === true && (
              <Link
              className={`flex w-full justify-between ${
                location.pathname === '/dashboard'
                  ? 'text-[rgb(254,240,138)]'
                  : 'text-white'
              } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
              to='/dashboard'
            >
                <div className='flex items-center'>
                  <Home />
                  <span className='text-md ml-2'>Dashboard</span>
                </div>
              </Link>
            )}
            {role.settings === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/taskcount'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/taskcount'
              >
                <div className='flex items-center'>
                  <Home />
                  <span className='text-md ml-2'>Dashboard</span>
                </div>
              </Link>
            )}
            {role.tasksView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/tasks'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/tasks'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Tasks</span>
                </div>
              </Link>
            )}
            {role.viewAttendance === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/banktasks'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/banktasks'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Bank Tasks</span>
                </div>
              </Link>
            )}
            {role.viewFieldTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/field-task'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/field-task'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Field Users Tasks</span>
                </div>
              </Link>
            )}
            {role.viewUnassignTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/unassigned'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/unassigned'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Un Assigned Tasks</span>
                </div>
              </Link>
            )}
            {role.transfers === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/transferred'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/transferred'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Transferred Tasks</span>
                </div>
              </Link>
            )}
            {role.finalisationPendingTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/finalisation'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/finalisation'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Finalisation Tasks</span>
                </div>
              </Link>
            )}
            {role.completedTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/completed'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/completed'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Completed Tasks</span>
                </div>
              </Link>
            )}
            {role.completedTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/jobdone'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/jobdone'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Done Tasks</span>
                </div>
              </Link>
            )}
            {role.inProgressTasks === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/inprogress'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/inprogress'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>In Progress Tasks</span>
                </div>
              </Link>
            )}
            {role.usersView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/users'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/users'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Users</span>
                </div>
              </Link>
            )}
            {role.resignedUsers === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/resigned'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/resigned'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Resigned Users</span>
                </div>
              </Link>
            )}
            {role.customersView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/customers'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/customers'
              >
                <div className='flex items-center'>
                  <AssignmentIndIcon />
                  <span className='text-md ml-2'>Customers</span>
                </div>
              </Link>
            )}
            {role.rolesView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/roles'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/roles'
              >
                <div className='flex items-center'>
                  <MergeTypeIcon />
                  <span className='text-md ml-2'>Roles</span>
                </div>
              </Link>
            )}
            {role.settings === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/settings'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/settings'
              >
                <div className='flex items-center'>
                  <Settings />
                  <span className='text-md ml-2'>Settings</span>
                </div>
              </Link>
            )}
            {role.pincodes === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/pincodes'
                    ? 'text-[rgb(254,240,138)]'
                    : 'text-white'
                } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                to='/pincodes'
              >
                <div className='flex items-center'>
                  <PersonPinCircle />
                  <span className='text-md ml-2'>Pincodes</span>
                </div>
              </Link>
            )}
            {/* {role.dashboard === true && (
              <div>
                <div
                  className={`flex w-full justify-between text-gray-600 hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6`}
                  onClick={toggleReportDropdown}
                >
                  <div className='flex items-center'>
                    <Assessment />
                    <span className='text-md ml-2'>Reports</span>
                  </div>
                  {isReportOpen ? <ExpandLess /> : <ExpandMore />}
                </div>
                {isReportOpen && (
                  <Link
                    className={`flex w-full justify-between ${
                      location.pathname === '/fieldcount'
                        ? 'text-[rgb(254,240,138)]'
                        : 'text-white'
                    } hover:text-[rgb(204,188,72)] cursor-pointer items-center mb-6 pl-8`}
                    to='/fieldcount'
                  >
                    <div className='flex items-center'>
                      <Assessment />
                      <span className='text-md ml-2'>Field Task Count</span>
                    </div>
                  </Link>
                )}
              </div>
            )} */}
          </ul>
        )}
      </div>
      <div className='px-8 border-t border-white'>
        <ul className='w-full flex items-center justify-between bg-[#013975]'>
          <li
            className='flex items-center w-full cursor-pointer text-white pt-5 pb-3'
            onClick={logoutClick}
          >
            <PowerSettingsNewIcon />
            <div className='flex flex-col'>
              <span className='text-sm ml-6'>{name}</span>
              <span className='text-sm ml-6'>Role: {role.name}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getBankTasks } from '../actions/taskActions'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'
import { Search } from 'lucide-react'

const BankTask = ({ history }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [limit] = useState(10) // Items per page
    const [hasSearched, setHasSearched] = useState(false)

    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const getRoleInfo = useSelector((state) => state.getRoleInfo)
    const { getRoleData } = getRoleInfo

    useEffect(() => {
        if (!userInfo || (getRoleData && !getRoleData.viewAttendance)) {
            history.push('/')
        }
    }, [userInfo, getRoleData, history])

    const fetchData = useCallback(() => {
        if (searchQuery.trim()) {
            setLoading(true)
            dispatch(getBankTasks(currentPage, limit, searchQuery))
            setHasSearched(true)
        }
    }, [dispatch, currentPage, limit, searchQuery])

    const getBankTasksInfo = useSelector((state) => state.getBankTasksInfo)
    const { loading: tasksLoading, error: tasksError, getBankTasksData } = getBankTasksInfo

    useEffect(() => {
        if (getBankTasksData) {
            setLoading(false)
            setData(getBankTasksData.data || [])
            setTotalPages(getBankTasksData.totalPages || 1)
        } else if (tasksError) {
            setLoading(false)
            toast(tasksError, {
                type: 'error',
                hideProgressBar: true,
                autoClose: 2000,
            })
        }
    }, [getBankTasksData, tasksError])

    const headCells = [
        { field: 'taskID', title: 'Task ID' },
        { field: 'assignTo', title: 'Assign To' },
        { field: 'name', title: 'Name' },
        { field: 'joinedOn', title: 'Allocation on field' },
        { field: 'status', title: 'Status' },
        { field: 'customerName', title: 'Customer' },
        { field: 'address', title: 'Address' },
        { field: 'loanID', title: 'Loan ID' },
        { field: 'assignBy', title: 'Assign By' },
        { field: 'loanType', title: 'Loan Type' },
        { field: 'type', title: 'Type' },
        { field: 'visitStartedOn', title: 'Visit Start' },
        { field: 'visitFinishedOn', title: 'Visit Finish' },
    ]

    const handleSearchInputChange = (e) => {
        const newQuery = e.target.value
        setSearchQuery(newQuery)
        if (newQuery === '') {
            resetSearch()
        }
    }

    const resetSearch = () => {
        setHasSearched(false)
        setData([])
        setCurrentPage(1)
        setTotalPages(0)
    }

    const handleSearch = () => {
        if (searchQuery.trim()) {
            setCurrentPage(1)
            fetchData()
        } else {
            resetSearch()
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        fetchData();
        window.scrollTo(0, 0);
    }, [fetchData]);

    if (loading || tasksLoading) {
        return <Loading />
    }

    return (
        <div id="layoutSidenav">
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid px-4">
                        <h1 className='text-2xl font-semibold'>Bank Tasks</h1>
                        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
                            <div className='flex justify-between items-center'>
                                <input
                                    type="text"
                                    placeholder="Search by Loan Number"
                                    className="form-control flex-grow mr-2"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <button
                                    onClick={handleSearch}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                    disabled={!searchQuery.trim()}
                                >
                                    <Search className="mr-2" size={18} />
                                    Search
                                </button>
                            </div>
                        </div>
                        {!hasSearched ? (
                            <div className="text-center py-10">
                                <p className="text-xl text-gray-600">Enter a search query and click Search to view bank tasks.</p>
                            </div>
                        ) : data.length > 0 ? (
                            <div className="w-full">
                                <div className="overflow-x-auto">
                                    <table className="w-full bg-white border border-gray-300">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                {headCells.map((cell) => (
                                                    <th key={cell.field} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {cell.title}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {data.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Link
                                                            className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'
                                                            to={`/tasks/${row._id}`}>
                                                            {row.taskID}
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.assignTo}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap w-48">{row.name}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.joinedOn}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.status}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.customerName}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.address}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.loanID}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.assignBy}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.loanType}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.type}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.visitStartedOn}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{row.visitFinishedOn}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-4">
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="text-center py-10">
                                <p className="text-xl text-gray-600">No results found. Try a different search query.</p>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default React.memo(BankTask)
import {
  ATTENDANCE_COUNT_FAIL,
  ATTENDANCE_COUNT_REQUEST,
  ATTENDANCE_COUNT_SUCCESS,
  ATTENDANCE_COUNT_RESET,
  GET_TASKS_ONE_REQUEST,
  GET_TASKS_ONE_SUCCESS,
  GET_TASKS_ONE_FAIL,
  GET_TASKS_ONE_RESET,
  TASK_COUNT_FAIL,
  TASK_COUNT_REQUEST,
  TASK_COUNT_SUCCESS,
  TASK_COUNT_RESET,
  FIELD_TASK_COUNT_RESET,
  FIELD_TASK_COUNT_FAIL,
  FIELD_TASK_COUNT_REQUEST,
  FIELD_TASK_COUNT_SUCCESS,
  FIELD_TASK_COUNT_HOUR_FAIL,
  FIELD_TASK_COUNT_HOUR_REQUEST,
  FIELD_TASK_COUNT_HOUR_SUCCESS,
  FIELD_TASK_COUNT_HOUR_RESET,
  TASK_COUNT_HOUR_FAIL,
  TASK_COUNT_HOUR_REQUEST,
  TASK_COUNT_HOUR_SUCCESS,
  TASK_COUNT_HOUR_RESET,
  TODAY_TASK_COUNT_FAIL,
  TODAY_TASK_COUNT_REQUEST,
  TODAY_TASK_COUNT_RESET,
  TODAY_TASK_COUNT_SUCCESS,
  TODAY_REGION_TASK_COUNT_FAIL,
  TODAY_REGION_TASK_COUNT_REQUEST,
  TODAY_REGION_TASK_COUNT_RESET,
  TODAY_REGION_TASK_COUNT_SUCCESS,
  TODAY_REGION_STATUS_TASK_COUNT_FAIL,
  TODAY_REGION_STATUS_TASK_COUNT_REQUEST,
  TODAY_REGION_STATUS_TASK_COUNT_RESET,
  TODAY_REGION_STATUS_TASK_COUNT_SUCCESS,
  TODAY_CUSTOMER_TASK_COUNT_SUCCESS,
  TODAY_CUSTOMER_TASK_COUNT_FAIL,
  TODAY_CUSTOMER_TASK_COUNT_REQUEST,
  TODAY_CUSTOMER_TASK_COUNT_RESET
} from '../constants/countConstants'

export const todayTaskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case TODAY_TASK_COUNT_REQUEST:
      return { loadingTodayTaskCountInfo: true }
    case TODAY_TASK_COUNT_SUCCESS:
      return { loadingTodayTaskCountInfo: false, getTodayTaskCountData: action.payload }
    case TODAY_TASK_COUNT_FAIL:
      return { loadingTodayTaskCountInfo: false, errorTodayTaskCountInfo: action.payload }
    case TODAY_TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const attendanceCountReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDANCE_COUNT_REQUEST:
      return { loadingAttendanceCountInfo: true }
    case ATTENDANCE_COUNT_SUCCESS:
      return { loadingAttendanceCountInfo: false, getAttendanceCountData: action.payload }
    case ATTENDANCE_COUNT_FAIL:
      return { loadingAttendanceCountInfo: false, errorAttendanceCountInfo: action.payload }
    case ATTENDANCE_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const taskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_COUNT_REQUEST:
      return { loadingTaskCountInfo: true }
    case TASK_COUNT_SUCCESS:
      return { loadingTaskCountInfo: false, getTaskCountData: action.payload }
    case TASK_COUNT_FAIL:
      return { loadingTaskCountInfo: false, errorTaskCountInfo: action.payload }
    case TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const fieldTaskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case FIELD_TASK_COUNT_REQUEST:
      return { loadingFieldTaskCountInfo: true }
    case FIELD_TASK_COUNT_SUCCESS:
      return { loadingFieldTaskCountInfo: false, getFieldTaskCountData: action.payload }
    case FIELD_TASK_COUNT_FAIL:
      return { loadingFieldTaskCountInfo: false, errorFieldTaskCountInfo: action.payload }
    case FIELD_TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const fieldTaskCountHourReducer = (state = {}, action) => {
  switch (action.type) {
    case FIELD_TASK_COUNT_HOUR_REQUEST:
      return { loadingFieldTaskCountHourInfo: true }
    case FIELD_TASK_COUNT_HOUR_SUCCESS:
      return { loadingFieldTaskCountHourInfo: false, getFieldTaskCountHourData: action.payload }
    case FIELD_TASK_COUNT_HOUR_FAIL:
      return { loadingFieldTaskCountHourInfo: false, errorFieldTaskCountHourInfo: action.payload }
    case FIELD_TASK_COUNT_HOUR_RESET:
      return {}
    default:
      return state
  }
}

export const TaskCountHourReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_COUNT_HOUR_REQUEST:
      return { loadingTaskCountHourInfo: true }
    case TASK_COUNT_HOUR_SUCCESS:
      return { loadingTaskCountHourInfo: false, getTaskCountHourData: action.payload }
    case TASK_COUNT_HOUR_FAIL:
      return { loadingTaskCountHourInfo: false, errorTaskCountHourInfo: action.payload }
    case TASK_COUNT_HOUR_RESET:
      return {}
    default:
      return state
  }
}

export const todayRegionTaskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case TODAY_REGION_TASK_COUNT_REQUEST:
      return { loadingTodayRegionTaskCountInfo: true }
    case TODAY_REGION_TASK_COUNT_SUCCESS:
      return { loadingTodayRegionTaskCountInfo: false, getTodayRegionTaskCountData: action.payload }
    case TODAY_REGION_TASK_COUNT_FAIL:
      return { loadingTodayRegionTaskCountInfo: false, errorTodayRegionTaskCountInfo: action.payload }
    case TODAY_REGION_TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const todayRegionStatusTaskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case TODAY_REGION_STATUS_TASK_COUNT_REQUEST:
      return { loadingTodayRegionStatusTaskCountInfo: true }
    case TODAY_REGION_STATUS_TASK_COUNT_SUCCESS:
      return { loadingTodayRegionStatusTaskCountInfo: false, getTodayRegionStatusTaskCountData: action.payload }
    case TODAY_REGION_STATUS_TASK_COUNT_FAIL:
      return { loadingTodayRegionStatusTaskCountInfo: false, errorTodayRegionStatusTaskCountInfo: action.payload }
    case TODAY_REGION_STATUS_TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const todayCustomerTaskCountReducer = (state = {}, action) => {
  switch (action.type) {
    case TODAY_CUSTOMER_TASK_COUNT_REQUEST:
      return { loadingTodayCustomerTaskCountInfo: true }
    case TODAY_CUSTOMER_TASK_COUNT_SUCCESS:
      return { loadingTodayCustomerTaskCountInfo: false, getTodayCustomerTaskCountData: action.payload }
    case TODAY_CUSTOMER_TASK_COUNT_FAIL:
      return { loadingTodayCustomerTaskCountInfo: false, errorTodayCustomerTaskCountInfo: action.payload }
    case TODAY_CUSTOMER_TASK_COUNT_RESET:
      return {}
    default:
      return state
  }
}

export const getTasksOneReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASKS_ONE_REQUEST:
      return { loadingGetTasksOneInfo: true };
    case GET_TASKS_ONE_SUCCESS:
      return { loadingGetTasksOneInfo: false, getTasksOneData: action.payload };
    case GET_TASKS_ONE_FAIL:
      return { loadingGetTasksOneInfo: false, errorGetTasksOneInfo: action.payload };
    case GET_TASKS_ONE_RESET:
      return { loadingGetTasksOneInfo: false, getTasksOneData: null, errorGetTasksOneInfo: null };
    default:
      return state;
  }
};
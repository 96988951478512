export const ATTENDANCE_COUNT_REQUEST = 'ATTENDANCE_COUNT_REQUEST'
export const ATTENDANCE_COUNT_SUCCESS = 'ATTENDANCE_COUNT_SUCCESS'
export const ATTENDANCE_COUNT_FAIL = 'ATTENDANCE_COUNT_FAIL'
export const ATTENDANCE_COUNT_RESET = 'ATTENDANCE_COUNT_RESET'

export const TASK_COUNT_REQUEST = 'TASK_COUNT_REQUEST'
export const TASK_COUNT_SUCCESS = 'TASK_COUNT_SUCCESS'
export const TASK_COUNT_FAIL = 'TASK_COUNT_FAIL'
export const TASK_COUNT_RESET = 'TASK_COUNT_RESET'

export const FIELD_TASK_COUNT_REQUEST = 'FIELD_TASK_COUNT_REQUEST'
export const FIELD_TASK_COUNT_SUCCESS = 'FIELD_TASK_COUNT_SUCCESS'
export const FIELD_TASK_COUNT_FAIL = 'FIELD_TASK_COUNT_FAIL'
export const FIELD_TASK_COUNT_RESET = 'FIELD_TASK_COUNT_RESET'

export const FIELD_TASK_COUNT_HOUR_REQUEST = 'FIELD_TASK_COUNT_HOUR_REQUEST'
export const FIELD_TASK_COUNT_HOUR_SUCCESS = 'FIELD_TASK_COUNT_HOUR_SUCCESS'
export const FIELD_TASK_COUNT_HOUR_FAIL = 'FIELD_TASK_COUNT_HOUR_FAIL'
export const FIELD_TASK_COUNT_HOUR_RESET = 'FIELD_TASK_COUNT_HOUR_RESET'

export const TASK_COUNT_HOUR_REQUEST = 'TASK_COUNT_HOUR_REQUEST'
export const TASK_COUNT_HOUR_SUCCESS = 'TASK_COUNT_HOUR_SUCCESS'
export const TASK_COUNT_HOUR_FAIL = 'TASK_COUNT_HOUR_FAIL'
export const TASK_COUNT_HOUR_RESET = 'TASK_COUNT_HOUR_RESET'

export const TODAY_TASK_COUNT_REQUEST = 'TODAY_TASK_COUNT_REQUEST'
export const TODAY_TASK_COUNT_SUCCESS = 'TODAY_TASK_COUNT_SUCCESS'
export const TODAY_TASK_COUNT_FAIL = 'TODAY_TASK_COUNT_FAIL'
export const TODAY_TASK_COUNT_RESET = 'TODAY_TASK_COUNT_RESET'

export const TODAY_REGION_TASK_COUNT_REQUEST = 'TODAY_REGION_TASK_COUNT_REQUEST'
export const TODAY_REGION_TASK_COUNT_SUCCESS = 'TODAY_REGION_TASK_COUNT_SUCCESS'
export const TODAY_REGION_TASK_COUNT_FAIL = 'TODAY_REGION_TASK_COUNT_FAIL'
export const TODAY_REGION_TASK_COUNT_RESET = 'TODAY_REGION_TASK_COUNT_RESET'

export const TODAY_REGION_STATUS_TASK_COUNT_REQUEST = 'TODAY_REGION_STATUS_TASK_COUNT_REQUEST'
export const TODAY_REGION_STATUS_TASK_COUNT_SUCCESS = 'TODAY_REGION_STATUS_TASK_COUNT_SUCCESS'
export const TODAY_REGION_STATUS_TASK_COUNT_FAIL = 'TODAY_REGION_STATUS_TASK_COUNT_FAIL'
export const TODAY_REGION_STATUS_TASK_COUNT_RESET = 'TODAY_REGION_STATUS_TASK_COUNT_RESET'

export const TODAY_CUSTOMER_TASK_COUNT_REQUEST = 'TODAY_CUSTOMER_TASK_COUNT_REQUEST'
export const TODAY_CUSTOMER_TASK_COUNT_SUCCESS = 'TODAY_CUSTOMER_COUNT_SUCCESS'
export const TODAY_CUSTOMER_TASK_COUNT_FAIL = 'TODAY_CUSTOMER_TASK_COUNT_FAIL'
export const TODAY_CUSTOMER_TASK_COUNT_RESET = 'TODAY_CUSTOMER_TASK_COUNT_RESET'

export const GET_TASKS_ONE_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_ONE_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_ONE_FAIL = 'GET_TASKS_FAIL';
export const GET_TASKS_ONE_RESET = 'GET_TASKS_RESET';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  addCustomerToUserReducer,
  addUserReducer,
  getRoleReducer,
  getUserByIDReducer,
  getUsersReducer,
  userLoginReducer,
  fetchAttendanceReducer,
  removeCustomerFromUserReducer,
  resetDeviceIDReducer,
  resetPasswordReducer,
  updateUserReducer,
  resignUserReducer,
  resignUsersReducer,
  rejoinUserReducer
} from './reducers/userReducers';
import {
  addCustomerReducer,
  getCustomersReducer,
  updateCustomerReducer
} from './reducers/customerReducers';
import {
  addRoleReducer,
  getRoleByIDReducer,
  getRolesReducer,
  updateRoleReducer,
} from './reducers/roleReducers';
import {
  taskUploadReducer,
  unassignedTasksReducer,
  transferredTasksReducer,
  reassignMultipleTasksReducer,
  collectionUploadReducer,
  getTasksReducer,
  searchTasksReducer,
  getBankTasksReducer,
  getFinalisationTasksReducer,
  getInProgressTasksReducer,
  getTasksIDReducer,
  getTaskIDReducer,
  taskUpdateRemarksReducer,
  taskUpdateSubmitReducer,
  taskCheckedReducer,
  taskFinalisationReducer,
  updateTaskByIDReducer,
  dialReducer,
  getFieldExecutiveTasksReducer,
  waiveTaskReducer,
  getCompletedTasksReducer,
  getJobDoneTasksReducer,
} from './reducers/taskReducer';
import {
  addPincodeReducer,
  getPincodeReducer,
  editPincodeReducer
} from './reducers/pincodeReducers';
import {
  currentAppVersionReducer,
  settingCallingDistanceReducer,
  settingReducer,
} from './reducers/settingReducers'
import {
  attendanceCountReducer,
  taskCountReducer,
  fieldTaskCountReducer,
  todayTaskCountReducer,
  todayRegionTaskCountReducer,
  todayRegionStatusTaskCountReducer,
  todayCustomerTaskCountReducer,
  fieldTaskCountHourReducer,
  getTasksOneReducer,
  TaskCountHourReducer,
} from './reducers/countReducers'

const reducer = combineReducers({
  fieldExecutiveTasks: getFieldExecutiveTasksReducer,
  unassignedTasks: unassignedTasksReducer,
  transferredTasks: transferredTasksReducer,
  reassignMultipleTasks: reassignMultipleTasksReducer,
  getTodayCustomerTaskCountInfo: todayCustomerTaskCountReducer,
  getTodayRegionStatusTaskCountInfo: todayRegionStatusTaskCountReducer,
  getTodayRegionTaskCountInfo: todayRegionTaskCountReducer,
  getTaskCountInfo: taskCountReducer,
  getFieldTaskCountInfo: fieldTaskCountReducer,
  getFieldTaskCountHourInfo: fieldTaskCountHourReducer,
  getTaskCountHourInfo: TaskCountHourReducer,
  getTodayTaskCountInfo: todayTaskCountReducer,
  getAttendanceCountInfo: attendanceCountReducer,
  taskUpload: taskUploadReducer,
  collectionUpload: collectionUploadReducer,
  getTasksInfo: getTasksReducer,
  getTasksOneInfo: getTasksOneReducer,
  searchTasksInfo: searchTasksReducer,
  getBankTasksInfo: getBankTasksReducer,
  getFinalisationTasksInfo: getFinalisationTasksReducer,
  getCompletedTasksInfo: getCompletedTasksReducer,
  getJobDoneTasksInfo: getJobDoneTasksReducer,
  getInProgressTasksInfo: getInProgressTasksReducer,
  getTasksIdInfo: getTasksIDReducer,
  getTaskIdInfo: getTaskIDReducer,
  updateTaskByIDInfo: updateTaskByIDReducer,
  taskCheckedInfo: taskCheckedReducer,
  taskUpdateSubmitInfo: taskUpdateSubmitReducer,
  taskUpdateRemarksInfo: taskUpdateRemarksReducer,
  taskFinalisationInfo: taskFinalisationReducer,
  dialInfo: dialReducer,
  addPincodeInfo: addPincodeReducer,
  getPincodeInfo: getPincodeReducer,
  editPincodeInfo: editPincodeReducer,
  userLogin: userLoginReducer,
  getRoleInfo: getRoleReducer,
  getCustomersInfo: getCustomersReducer,
  addCustomerInfo: addCustomerReducer,
  updateCustomerInfo: getCustomersReducer,
  getRolesInfo: getRolesReducer,
  addRoleInfo: addRoleReducer,
  getRoleByIDInfo: getRoleByIDReducer,
  updateRoleInfo: updateRoleReducer,
  getUsersInfo: getUsersReducer,
  addUserInfo: addUserReducer,
  resignUsersInfo: resignUsersReducer,
  resignUserInfo: resignUserReducer,
  rejoinUserInfo: rejoinUserReducer,
  getUserByIDInfo: getUserByIDReducer,
  addCustomerToUserInfo: addCustomerToUserReducer,
  updateUserInfo: updateUserReducer,
  resetDeviceIDInfo: resetDeviceIDReducer,
  resetPasswordInfo: resetPasswordReducer,
  removeCustomerFromUserInfo: removeCustomerFromUserReducer,
  fetchAttendanceInfo: fetchAttendanceReducer,
  settingInfo: settingReducer,
  settingCallingDistanceInfo: settingCallingDistanceReducer,
  currentAppVersionInfo: currentAppVersionReducer,
  waiveTaskInfo: waiveTaskReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const inititalState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

// Conditionally apply Redux DevTools extension
const composeEnhancers = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const store = createStore(
  reducer,
  inititalState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
